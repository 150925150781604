import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('div',{staticClass:"d-flex"},[_c('div',[_c(VCardTitle,{staticClass:" "},[_vm._v(" "+_vm._s(_vm.statTitle)+" ")]),_c(VCardText,{staticClass:"d-flex align-center"},[_c('h2',{staticClass:"text-2xl font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.statistics)+" ")]),_c('span',{staticClass:"text-xs ms-1",class:_vm.checkChange(_vm.change) ? 'success--text':'error--text'},[_vm._v(" "+_vm._s(_vm.change))])]),_c(VCardText,[_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold",class:"primary--text",attrs:{"small":"","color":_vm.chipColor}},[_vm._v(" "+_vm._s(_vm.chipText)+" ")])],1)],1),_c(VSpacer),(_vm.icon.length > 0)?_c(VIcon,{attrs:{"size":"150","color":"#6092F3"}},[_vm._v(_vm._s(_vm.icon))]):_c('div',{staticClass:"illustrator-img pb-5"},[_c(VImg,{attrs:{"width":_vm.avatarWidth,"src":_vm.avatar}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }