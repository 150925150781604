<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="4"
      >
        <statistics-card-with-images
          class="fill-height"
          :icon="ratingsOptions.icon"
          :avatar="ratingsOptions.avatar"
          :avatar-width="ratingsOptions.avatarWidth"
          :chip-color="ratingsOptions.chipColor"
          :chip-text="ratingsOptions.chipText"
          :statistics="totalAmountList"
          :stat-title="ratingsOptions.statTitle"
        ></statistics-card-with-images>
      </v-col>
      <v-col
        cols="12"
        sm="8"
      >
        <v-card class="fill-height">
          <v-card-title>Suchen</v-card-title>
          <v-card-text>
            <div class="d-flex align-center pb-5">
              <v-text-field
                v-model="filter.searchString"
                dense
                outlined
                hide-details
                clearable
                placeholder="Rechnung suchen"
                class="channel-list-search me-3"
              ></v-text-field>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="listInvoices"
          :options.sync="options"
          :server-items-length="totalAmountList"
          :items-per-page="options.itemsPerPage"
          :loading="loading"
          :search="filter.searchString"
          class="text-no-wrap"
          no-data-text="Keine Einträge vorhanden"
          no-results-text="Ihre Suche ergab keinen Treffer"
          :footer-props="{
            itemsPerPageText: 'Anzahl pro Seite',
            itemsPerPageAllText: 'Alle',
            pageText: '{0}-{1} von {2}',
            itemsPerPageOptions: [5,10,25,50,-1]
          }"
        >
          <template #[`item.idPtRechnung`]="{item}">
            #{{ item.idPtRechnung }}
          </template>
          <template #[`item.actions`]="{item}">
            <div class="">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <a
                    :href="`/api/rechnung/?aktion=openInvoicePdf&mainData[idPtRechnung]=${item.idPtRechnung}`"
                    :download="`${item.rechnungsNummer}.pdf`"
                    target="_self"
                  >
                    <v-btn icon small v-bind="attrs" v-on="on">
                      <v-icon size="18">{{ icons.mdiFilePdf }}</v-icon>
                    </v-btn>
                  </a>
                </template>
                <span>Download PDF</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiFilePdf
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import { avatarText } from '@core/utils/filter'
import InlineDatePicker from "@/views/InlineDatePicker.vue";
import router from '@/router'
import StatisticsCardWithImages from "@core/components/statistics-card/StatisticsCardWithImages";
import themeConfig from '@themeConfig'

export default {
  name: 'channel-list',
  components: {
    InlineDatePicker,
    StatisticsCardWithImages
  },
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const ratingsOptions = {
      statTitle: 'Rechnungen',
      statistics: '13k',
      change: '+38%',
      chipText: 'Gesamt',
      chipColor: 'error',
      avatar: require('@/assets/images/nitramit/2024-02-26-icon-werbemittel-blau.png'),
      avatarWidth: '111',
    }

    return {
      vm,
      router,
      ratingsOptions
    }
  },
  data() {
    return {
      avatarText:avatarText,
      actions: ['Delete', 'Edit'],
      icons: {
        mdiFilePdf
      },
      filter: { searchString: '', status: 'offene',datumVon:'',datumBis:''},
      loading: false,
      totalAmountList: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idPtRechnung'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#',
          align: 'start',
          value: 'idPtRechnung',
        },
        {text: 'RE-Nummer', value: 'rechnungsNummer'},
        {text: 'RE-Datum', value: 'rechnungsDatumDE'},
        {text: 'Leistungszeitraum von', value: 'leistungszeitraumVonDE'},
        {text: 'Leistungszeitraum bis', value: 'leistungszeitraumBisDE'},
        {text: 'RE-Betrag', value: 'rechnungsBetragBruttoDE',align: 'right',},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listInvoices: [],
    }
  },
  mounted() {
    this.fetchInvoices()
  },
  watch: {
    options: {
      handler() {
        this.fetchInvoices()
      },
    },
    filter: {
      handler() {
        this.fetchInvoices()
      },
      deep: true,
    },
  },
  methods: {
    fetchInvoices() {
      this.loading = true
      axios.post('api/rechnung/', {
        aktion: 'showOverviewInvoices',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listInvoices = response.data.invoices || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    updateFilter (key,value) {
      this.$nextTick(() => {
        this.filter[key] = value;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
#channel-list {
  .channel-list-search {
    max-width: 10.625rem;
  }
}
</style>
